import React from "react";

import './TitleContainer.css'

interface Props {
	title: string[]
	text?: string
}


export default function TitleContainer(props: Props) {
	return (
		<div className={'titleContainer'}>
			<h1>{props.title[0]}<br />{props.title[1]}</h1>
			{props.text && <h2>{props.text}</h2>}
		</div>
	);
}