import {useEffect, useState} from 'react';

interface WindowSize {
  width: number
  height: number
}

export default function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width:  0,
		height: 0
	});

	const handleSize = () => {
		setWindowSize({
			width:  window.innerWidth,
			height: window.innerHeight
		});
	};

	useEffect(() => {
		setWindowSize({
			width:  window.innerWidth,
			height: window.innerHeight
		});

		window.addEventListener('resize', handleSize);
		return () => window.removeEventListener('resize', handleSize);
	}, []);

	return windowSize;
}