import React, { useState } from 'react';

import Home from './Home/Home';
import TwoD from './2d/2d';
import ThreeD from './3d/3d';
import Reference from './Reference/Reference';

import {View} from './ressources/global';

import TEXTE from './ressources/text';

import img_logoSobi from './ressources/images/logo/sobi.svg';

import './App.css';
import './2d/2d.css';


function App() {
	const [view, set_view] = useState<View>('start');
	const [popup, set_popup] = useState(true);

	if (popup) {
		return (
			<div className='main'>
				<div className={'popupModal'}>
					<img alt={'sobi'} src={img_logoSobi} />
					<div className={'popupModalText'}>
						<p>{TEXTE.popup.text[0]}</p>
						<p>{TEXTE.popup.text[1]}</p>
					</div>

					<div className={'popupModalButtonContainer'}>
						<button
							className={'popupModalButton'}
							onClick={() => set_popup(false)}
						>
							{TEXTE.popup.yes}
						</button>
						<a
							className={'popupModalButton'}
							href={'https://www.sobi.com/en'}
						>
							{TEXTE.popup.no}
						</a>
					</div>
				</div>
			</div>
		);
	}

  	return (
		<div className='main'>
			{(view === 'start' || view === 'home') &&
				<Home view={view} setView={set_view} />
			}

			{view === '2d' && <TwoD view={view} setView={set_view} />}
			{view === '3d' && <ThreeD view={view} setView={set_view} />}

			{view !== 'start' && <Reference view={view} setView={set_view} />}
		</div>
	);
}

export default App;
