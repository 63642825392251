import React, {Suspense} from 'react';
import {Canvas} from '@react-three/fiber';
import {OrbitControls, useFBX} from '@react-three/drei';
import {Box3, Vector3} from 'three';

function RotatingFBXModel() {
	const fbx = useFBX('/Efa_V05_Color_low.fbx');
	fbx.scale.set(1.35, 1.35, 1.35); // Ajustez la mise à l'échelle

	const box = new Box3().setFromObject(fbx);
	const center = new Vector3();

	box.getCenter(center);
	fbx.position.sub(center);

	return (
		<group>
			<primitive object={fbx} />
		</group>
	);
}

export default function Object3D() {
	return (
		<Canvas className={'threeDCanvas'}>
			<ambientLight intensity={3} />

			<Suspense fallback={null}>
				<RotatingFBXModel />
			</Suspense>

			<OrbitControls
				enableZoom={false}
				enablePan={false}
			/>
		</Canvas>
	);
}