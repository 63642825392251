import React, { useEffect } from 'react';

import {IntProps} from '../ressources/global';

import TEXTE from '../ressources/text';

import img_logo from '../ressources/images/logo/app.svg';
import img_logoSimplify from '../ressources/images/logo/app-simplify.svg';
import img_logoSobi from '../ressources/images/logo/sobi.svg';
import './Home.css';


export default function Home(props: IntProps) {
	useEffect(() => {
		if (props.view === 'start')
			setTimeout(() => props.setView('home'), 3000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  	return (
		<div
			className={'homeMain'}
			// onClick={() => props.view === 'start' && props.setView('home')}
		>
			{props.view === 'start' ?
				<div className={'homeCenter'}>
					<img
						alt={''}
						src={img_logo}
						className={'homeImage'}
					/>
				</div>
				:
				<div
					data-view={'home'}
					className={'homeCenter'}
				>
					{props.view === 'home' &&
						<h1 className={'homeTitle'}>{TEXTE.home.title}</h1>
					}

					<img
						alt={''}
						className={'homeImage'}
						src={img_logoSimplify}
					/>
				</div>
			}

			{props.view === 'start' &&
				<img
					height={40}
					width={107}
					alt={'sobi'}
					src={img_logoSobi}
					className={'homeImageSobi'}
				/>
			}

			{props.view === 'home' &&
				<button
					className={'homeButton'}
					onClick={() => props.setView('2d')}
				>
					{TEXTE.home.button}
				</button>
			}
		</div>
	);
}

