import React, {useState} from 'react';

import Header from '../Header/Header';
import Object3D from './3dObject';
import TextContainer from '../ressources/modules/TextContainer/TextContainer';
import TitleContainer from '../ressources/modules/TitleContainer/TitleContainer';

import TEXTE from '../ressources/text';

import useWindowSize from '../ressources/useWindowSize';

import {IntProps} from '../ressources/global';

import img_rotate from '../ressources/images/icons/rotate.svg';
import img_rotateMobile from '../ressources/images/icons/rotate-mobile.svg';

import './3d.css';


export default function ThreeD (props: IntProps) {
	const [current, set_current] = useState('');

	const size = useWindowSize();

	function renderClick(id: string) {
		return (
			<div
				data-id={id}
				className={'threeDClick'}
				data-actual={current === id}
				onClick={() => set_current(id)}
			/>
		)
	}

	function renderBottom() {
		const render: React.ReactElement[] = [];

		if (current) {
			let text: ('text1' | 'text2' | 'text2_mobile') = current === '1' ? 'text1' : 'text2';

			if (size.width < 1024) {
				if (current === '2' && size.width <= 430)
					text = 'text2_mobile';

				return (
					<TextContainer
						padding={text === 'text2_mobile' ? 22 : 24}
						text={TEXTE.threeD[text]}
					/>
				)
			}

			render.push(
				<TextContainer
					padding={24}
					text={TEXTE.threeD[`${text}_computer`]}
				/>
			);
		}

		return (
			<div className={'threDRotateText'}>
				<img src={size.width > 1024 ? img_rotate : img_rotateMobile} alt={''} />
				<p>
					{TEXTE.threeD.finger[1]}<br />
					{TEXTE.threeD.finger[2]}
				</p>

				{render}
			</div>
		)
	}

	return (
		<div className={'threeDMain'}>
			<Header {...props} />

			<TitleContainer title={TEXTE.threeD.header[current ? 'secondaryTitle' : 'title']} />

			<div className={'threeDGameContainer'} >
				<Object3D/>

				{renderClick('1')}
				{renderClick('2')}
			</div>

			<div className={'threeDBottomContainer'}>
				{renderBottom()}
			</div>
		</div>
	);
};