const text = {
	popup: {
		text: [
			'This information is for healthcare professional.',
			' Are you a healthcare professional ?'
		],
		yes: 'Yes',
		no: 'No'
	},

	home: {
		title: 'This experience is a sample of the MR experience:',
		button: 'Start'
	},

	twoD: {
		header: {
			title: [
				'REBUILD THE',
				'EFANESOCTOCOG ALFA MOLECULE'
			],
			text: 'Select the components and position them correctly'
		},

		button: 'Unlock the 3D interaction',

		modal: {
			1: {
				title: 'Fc domain',
				text: ['Extends half-life through', 'FcRn-mediated recycling pathway']
			},
			2: {
				title: ['Covalent linkage to', '', 'the D’D3 domain of VWF'],
				text: [
					'Prevents binding to endogenous',
					'VWF, decoupling from',
					'VWF-mediated clearance',
					'',
					'Provides partial protection from',
					'degradation normally afforded by VWF'
				]
			},
			3: {
				title: 'XTEN polypeptides',
				text: [
					'Hydrophilic sequences comprising',
					'natural amino acids',
					'',
					'Shield from proteolytic degradation',
					'and binding to clearance receptors'
				],
				textMobile: [
					'Hydrophilic sequences',
					'comprising natural',
					'amino acids',
					'',
					'Shield from proteolytic',
					'degradation and binding to',
					'clearance receptors'
				]
			},
			4: {
				title: 'Added thrombin cleavage site',
				text: ['Enables release of D’D3-XTEN moiety', 'upon thrombin activation'],
				textMobile: [
					'Enables release of D\'D3',
					'XTEN moiety upon thrombin',
					'activation',
				]
			}
		}
	},

	threeD: {
		header: {
			title: [
				'TO UNLOCK INTERACTIVE AREA,',
				'MANIPULATE THE 3D EFANESOCTOCOG ALFA'
			],
			secondaryTitle: ['BIODEGRADATION OF XTEN AND D’D3-XTEN']
		},

		finger: {
			1: 'With your finger,',
			2: 'you can interact directly with the molecule'
		},

		text1: ['Efanesoctocog alfa is proteolytically', 'degraded.'],
		text2: [
			'As XTEN is a polypeptide and made of',
			'amino acids, it is proteolytically degraded',
			'like other components of rFVIII-Fc fusion',
			'protein i.e., FVIII, D’D3 and Fc portions',
			'rFVIII-Fc fusion protein will break into',
			'smaller peptides and ultimately into amino',
			'acids'
		],
		text1_computer: ['Efanesoctocog alfa is proteolytically', 'degraded.'],
		text2_computer: [
			'As XTEN is a polypeptide and made',
			'of amino acids, it is proteolytically',
			'degraded like other components of',
			'rFVIII-Fc fusion protein i.e., FVIII,',
			'D’D3 and Fc portions rFVIII-Fc fusion',
			'protein will break into smaller peptides',
			'and ultimately into amino acids'
		],
		text2_mobile: [
			'As XTEN is a polypeptide and made of',
			'amino acids, it is proteolytically ',
			'degraded like other components of',
			'rFVIII-Fc fusion protein i.e., FVIII, D\'D3',
			'and Fc portions rFVIII-Fc fusion protein',
			'will break into smaller peptides and',
			'ultimately into amino acids'
		]
	},

	reference: {
		title: 'REFERENCES',

		text: {
			default: [
				'Seth Chhabra E, Liu T, Kulman J, et al. BIVV001, a',
				'new class of factor VIII replacement for hemophilia',
				'A that is independent of von Willebrand factor in',
				'primates and mice. Blood.',
				'2020;135(17):1484-1496.',
				'',
				'Podust, Vladimir N., et al. « Extension of in vivo',
				'half-life of biologically active molecules by XTEN',
				'protein polymers. » Journal of Controlled Release',
				'240 (2016): 52-66.',
			],
			computer: [
				'Seth Chhabra E, Liu T, Kulman J, et al. BIVV001, a new class of factor VIII replacement for hemophilia A that is independent of von Willebrand factor in primates and mice. Blood. 2020;135(17):1484-1496.',
				'',
				'Podust, Vladimir N., et al. « Extension of in vivo half-life of biologically active molecules by XTEN protein polymers. » Journal of Controlled Release 240 (2016): 52-66.',
			],
			mobile: [
				'Seth Chhabra E, Liu T, Kulman J, et al.',
				'BIVV001, a new class of factor VIII',
				'replacement for hemophilia A that is',
				'independent of von Willebrand factor in',
				'primates and mice. Blood.',
				'2020;135(17):1484-1496.',
				'',
				'Podust, Vladimir N., et al. « Extension of in',
				'vivo half-life of biologically active molecules',
				'by XTEN protein polymers. » Journal',
				'of Controlled Release 240 (2016): 52-66.',
			]
		},

		footer: [
			'Swedish Orphan Biovitrum AG',
			'Riehenring 182, 4058 Basel, Tel. +41 41 220 24 40',
			'',
			'Swedish Orphan Biovitrum GmbH',
			'Dorotheergasse 6 – 8/24, 1010 Wien, Tel. +43 1 934 695 80',
			'Fax +43 1 934 695 89 18,',
			'',
			'Sobi is a trademark of Swedish Orphan Biovitrum AB (publ).',
			'© 2024 Swedish Orphan Biovitrum AB (publ). All rights reserved.',
			'226-HAE-ACH(E)-0824-V01-NP-34740',
			'references'
		]
	}
};

export default text;