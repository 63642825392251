import React from 'react';

import {IntProps} from '../ressources/global';

import img_logo from '../ressources/images/logo/app-simplify.svg';
import img_2d from '../ressources/images/header/2d.svg';
import img_3d from '../ressources/images/header/3d.svg';
import './Header.css';


export default function Header(props: IntProps) {
  	return (
		<div className={'headerMain'}>
			<img
				alt={''}
				src={img_logo}
				className={'headerLogo'}
				onClick={() => props.setView('home')}
			/>

			<div
				className={'headerIcon'}
				onClick={() => props.setView(props.view === '2d' ? '3d' : '2d')}
			>
				<img alt={''} src={props.view === '2d' ? img_3d : img_2d} />
			</div>
		</div>
	);
}

