import React from 'react';

import Header from '../Header/Header';

import TEXTE from '../ressources/text';
import TitleContainer from '../ressources/modules/TitleContainer/TitleContainer';
import {IntProps} from '../ressources/global';

import useWindowSize from '../ressources/useWindowSize';

import img_logoSobi from '../ressources/images/logo/sobi.svg';
import './Reference.css';


export default function Reference(props: IntProps) {
	const size = useWindowSize();

	function renderText() {
		return (
			<>
				<div>
					<p>{TEXTE.reference.footer[0]}</p>
					<p>{TEXTE.reference.footer[1]}</p>
					<p>
						<a href={'mailto:mail.ch@sobi.com'}>{'mail.ch@sobi.com'}</a>
						{', '}{size.width <= 430 && <br />}
						<a href={'https://www.sobi.com/switzerland'} target={'_blank'} rel={'noreferrer'}>{'www.sobi.com/switzerland'}</a>
					</p>
				</div>

				<div>
					<p>{TEXTE.reference.footer[3]}</p>
					<p>{TEXTE.reference.footer[4]}</p>
					<p>{TEXTE.reference.footer[5]}</p>
					<p>
						<a href={'mailto:mail.at@sobi.com'}>{'mail.at@sobi.com'}</a>
						{', '}{size.width <= 430 && <br />}
						<a href={'https://www.sobi.com/austria'} target={'_blank'} rel={'noreferrer'}>{'www.sobi.com/austria'}</a>
					</p>
				</div>

				<div>
					<p>{TEXTE.reference.footer[7]}</p>
					<p>{TEXTE.reference.footer[8]}</p>
					<p>{TEXTE.reference.footer[9]}</p>
				</div>
			</>
		);
	}

	function renderFooter() {
		return (
			<div className={'referenceFooterMain'}>
				<div className={'referenceFooterTextContainer'}>
					{renderText()}

					<div>
						<p
							onClick={() => props.setView('reference')}
							style={{textDecoration: 'underline', cursor: 'pointer'}}
						>{TEXTE.reference.footer[10]}</p>
					</div>
				</div>

				<img
					alt={'sobi'}
					src={img_logoSobi}
					className={'referenceFooterImage'}
				/>
			</div>
		);
	}

	if (props.view !== 'reference')
		return renderFooter();

	let text = size.width > 1024 ? TEXTE.reference.text.computer : TEXTE.reference.text.default;

	if (size.width <= 430)
		text = TEXTE.reference.text.mobile;

	return (
		<div className={'referenceMain'}>
			<Header {...props} />

			<TitleContainer title={[TEXTE.reference.title]} />

			<div className={'referenceTextContainer'}>
				<div>
					{text.map(t => <>{t}<br /></>)}
				</div>
				{renderText()}
			</div>

			<img
				alt={'sobi'}
				src={img_logoSobi}
				className={'referenceImage'}
			/>
		</div>
	);
}

