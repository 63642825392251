import React, {useState, useEffect, useRef, ReactElement, useMemo} from 'react';

import Header from '../Header/Header';

import TEXTE from '../ressources/text';

import useWindowSize from '../ressources/useWindowSize';
import useScrollPosition from '../ressources/useScrollPosition';

import img_initial from '../ressources/images/2d/initial.svg';

import img_piece1 from '../ressources/images/2d/piece-1.svg';
import img_piece2 from '../ressources/images/2d/piece-2.svg';
import img_piece3 from '../ressources/images/2d/piece-3.svg';
import img_piece4 from '../ressources/images/2d/piece-4.svg';

import img_empty1 from '../ressources/images/2d/empty-1.svg';
import img_empty2 from '../ressources/images/2d/empty-2.svg';
import img_empty3 from '../ressources/images/2d/empty-3.svg';
import img_empty4 from '../ressources/images/2d/empty-4.svg';
import img_empty3Little from '../ressources/images/2d/empty-3-little.svg';
import img_empty4Little from '../ressources/images/2d/empty-4-little.svg';

import img_validate from '../ressources/images/icons/validate.svg';
import img_validateGreen from '../ressources/images/icons/validate-green.svg';
import img_cross from '../ressources/images/icons/cross.svg';
import img_crossRed from '../ressources/images/icons/cross-red.svg';
import img_crossYellow from '../ressources/images/icons/cross-yellow.svg';

import './2d.css';
import { IntProps } from '../ressources/global';
import TextContainer from '../ressources/modules/TextContainer/TextContainer';
import TitleContainer from '../ressources/modules/TitleContainer/TitleContainer';

interface IntDraggable {
	src: string
	initialPosition: DOMRect
	targetPosition: DOMRect
	onNext: () => void
	scroll: number,
	screenWidth: number,
	isDrag:  boolean,
	setIsDrag: (val: boolean) => void
}

const DraggableImage = (props: IntDraggable) => {
	const [position, setPosition] = useState<{top: number, left: number}>(props.initialPosition);
	const [isDropped, setIsDropped] = useState(false);
	const imageRef = useRef<HTMLImageElement>(null);

	const margin = useMemo(() => {
		if (props.screenWidth <= 1640)
			return 0;
		return ((props.screenWidth - 1640) / 2);
	}, [props.screenWidth]);

	useEffect(() => {
		if (isDropped)
			setPosition(props.targetPosition);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.targetPosition]);

	useEffect(() => {
		if (!isDropped)
			setPosition(props.initialPosition);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.initialPosition]);

	const handleDragStart = (e: any) => {
		if (!imageRef.current)
			return;

		e.dataTransfer.setDragImage(new Image(), 0, 0); // Cache l'image pendant le drag
		imageRef.current.style.opacity = "0"; // Change l'opacité pendant le drag
		props.setIsDrag(true);
	};

	const handleDragEnd = () => {
		if (!imageRef.current)
			return;

		imageRef.current.style.opacity = "1"; // Remet l'opacité à 1
		props.setIsDrag(false);
	};

	function onDrop(e: any) {
		if (!props.isDrag)
			return;

		e.preventDefault();
		setPosition(props.targetPosition);
		setIsDropped(true);
		props.onNext();
	}

	const handleTouchStart = (e: any) => {
		if (!imageRef.current)
			return;

		e.preventDefault();
		if (!isDropped) {
			setPosition({
				left: e.clientX - imageRef.current.offsetWidth / 2,
				top:  e.clientY - imageRef.current.offsetHeight / 2,
			});
		}
		props.setIsDrag(true);
	};

	const handleTouchMove = (e: any) => {
		if (!imageRef.current)
			return;

		e.preventDefault();
		const touch = e.touches[0];
		if (!isDropped) {
			setPosition({
				left: touch.clientX - imageRef.current.offsetWidth / 2,
				top:  touch.clientY - imageRef.current.offsetHeight / 2,
			});
		}
	};

	const handleTouchEnd = () => {
		const rect = imageRef.current?.getBoundingClientRect();
		props.setIsDrag(false);

		if (!rect)
			return props.setIsDrag(false);

		const isInsideTarget = rect.left >= props.targetPosition.left - 50 &&
							 rect.top >= props.targetPosition.top - 50 &&
							 rect.right <= props.targetPosition.left + props.targetPosition.width + 50 &&
							 rect.bottom <= props.targetPosition.top + props.targetPosition.height + 50;
		if (isInsideTarget) {
			setPosition(props.targetPosition);
			setIsDropped(true);
			props.onNext();
		} else
			setPosition(props.initialPosition);
	};

	return (
		<div style={{position: 'absolute'}}>
			<div
				onDrop={onDrop}
				onDragOver={e => props.isDrag ? e.preventDefault() : undefined}
				style={{
					zIndex:   100,
					position: 'absolute',
					opacity:  isDropped ? 0 : 0.2,
					left:     props.targetPosition.left - margin,
					top:      props.targetPosition.top + props.scroll,
					width:    props.targetPosition.width,
					height:   props.targetPosition.height
				}}
			/>,
			<img
				alt={''}
				draggable
				ref={imageRef}
				src={props.src}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
				style={{
					zIndex:      101,
					touchAction: 'none',
					position:    'absolute',
					opacity:     (props.screenWidth < 1024 && props.isDrag) ? 1 : 0.0001,
					left:        position.left - margin,
					top:         position.top + props.scroll,
					cursor:      isDropped ? 'default' : 'move',
					height:      (props.isDrag || isDropped) ? props.targetPosition.height : props.initialPosition.height,
					width:       (props.isDrag || isDropped) ? props.targetPosition.width : props.initialPosition.width,
				}}
			/>
		</div>);
};

export default function TwoD (props: IntProps) {
	const [rerender, set_rerender] = useState(0);
	const [current, set_current] = useState(1);
	const [isDrag, set_isDrag] = useState<number | false>(false);
	const [modal, set_modal] = useState(false);

	const scroll = useScrollPosition();
	const size = useWindowSize();

	const piece1 = useRef<HTMLDivElement>(null);
	const piece2 = useRef<HTMLDivElement>(null);
	const piece3 = useRef<HTMLDivElement>(null);
	const piece4 = useRef<HTMLDivElement>(null);

	const init1 = useRef<HTMLImageElement>(null);
	const init2 = useRef<HTMLImageElement>(null);
	const init3 = useRef<HTMLImageElement>(null);
	const init4 = useRef<HTMLImageElement>(null);

	const images = [
		{src: img_piece1, empty: img_empty1, initialPosition: init1, targetPosition: piece1},
		{src: img_piece2, empty: img_empty2, initialPosition: init2, targetPosition: piece2, zIndex: 11},
		{src: img_piece3, empty: img_empty3, initialPosition: init3, targetPosition: piece3, zIndex: 11},
		{src: img_piece4, empty: img_empty4, initialPosition: init4, targetPosition: piece4},
	];

	useEffect(() => {
		setTimeout(() => set_rerender(rerender + 1), 100);
		setTimeout(() => set_rerender(rerender + 2), 200);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [piece1, piece2, piece3, piece4, init1, init2, init3, init4]);

	useEffect(() => {
		if (current > 1)
			set_modal(true)
	}, [current]);

	if (!piece1 || !piece2 || !piece3 || !piece4 || !init1 || !init2 || !init3 || !init4)
		set_rerender(rerender + 1);

	function renderTarget(id: number) {
		const image = images[id - 1];

		return (
			<div
				ref={image.targetPosition}
				className={`twoDPiece p${id}`}
				style={{zIndex: image.zIndex}}
			>
				<img src={current > id ? image.src : image.empty} alt='' />
			</div>
		);
	}

	function renderName() {
		return (
			<div className={'twoDNameContainer'}>
				{current === 1 && <span style={{opacity: 0}}>{'.'}</span>}
				{current >= 2 && <span>
					<span style={{color: '#715EC9'}}>{'rFVIII'}</span>
					<span style={{color: '#FCB813'}}>{'Fc'}</span>
				</span>}
				{current >= 3 && <span>
					<span style={{color: '#FFC09F'}}>{'-'}</span>
					<span style={{color: '#DA1C5C'}}>{'D’D3'}</span>
					<span style={{color: '#FFC09F'}}>{'-'}</span>
				</span>}
				{(current >= 3 && current < 5) && <span style={{color: '#FFC09F'}}>{'Fusion'}</span>}
				{current >= 5 && <span style={{color: '#F5F5F5'}}>{'XTEN'}</span>}
			</div>
		)
	}

	function renderInital(id: number, forceEmpty?: string) {
		const image = images[id - 1];
		let src = image.src;

		if (current > id)
			src = image.empty;
		if (current === id && isDrag === current)
			src = image.empty;

		if (src === image.empty && forceEmpty)
			src = forceEmpty;

		return (
			<div className={'init'} >
				<img ref={image.initialPosition} src={src} alt='' />
			</div>
		);
	}

	function renderPieces() {
		const render: ReactElement[] = [];

		images.forEach((image, index) => {
			if (current !== index + 1 || !image.targetPosition?.current || !image.initialPosition?.current)
				return;

			render.push(
				<DraggableImage
					key={index}
					src={image.src}
					scroll={scroll}
					screenWidth={size.width}
					isDrag={isDrag === current}
					onNext={() => set_current(current + 1)}
					setIsDrag={val => set_isDrag(val ? current : false)}
					targetPosition={image.targetPosition.current.getBoundingClientRect()}
					initialPosition={image.initialPosition.current.getBoundingClientRect()}
				/>
			);
		})

		return render;
	}

	function renderModal() {
		const modalValue = current - 1;

		if (modalValue !== 1 && modalValue !== 2 && modalValue !== 3 && modalValue !== 4)
			return;

		let color: 'yellow' | 'red' | undefined;
		let padding: string | undefined;

		if (current === 2)
			color = 'yellow';
		else if (current === 3)
			color = 'red';

		if (modalValue === 2 && size.width <= 430) {
			padding = '25.2px 21px 25.2px 21px';

			if (size.width <= 385)
				padding = '25.2px 15px 25.2px 15px';
		}

		return (
			<div className={'twoDModal'}>
				<img
					alt={''}
					className={'twoDModalIcon'}
					onClick={() => set_modal(false)}
					src={(
						color === 'yellow' ?
							img_crossYellow
							:
							(color ? img_crossRed : img_cross)
					)}
				/>

				<div>
					{modalValue >= 3 &&
						<TextContainer
							text={TEXTE.twoD.modal[3][size.width <= 430 ? 'textMobile' : 'text']}
							title={TEXTE.twoD.modal[3].title}
						/>
					}

					{modalValue !== 3 &&
						<TextContainer
							color={color}
							padding={padding}
							title={TEXTE.twoD.modal[modalValue].title}
							text={(modalValue === 4 && size.width <= 430) ? TEXTE.twoD.modal[4].textMobile : TEXTE.twoD.modal[modalValue].text}
						/>
					}
				</div>
			</div>
		);
	}

	return (
		<div className={'twoDMain'}>
			<Header {...props} />

			<TitleContainer
				text={TEXTE.twoD.header.text}
				title={TEXTE.twoD.header.title}
			/>

			<div className={'twoDGameContainer'} >
				<div className={'twoDBoard'}>
					<img src={img_initial} alt={''} />

					{renderTarget(1)}
					{renderTarget(2)}
					{renderTarget(3)}
					{renderTarget(4)}
				</div>
			</div>

			{/* <div className={'twoDBottomContainer'}> */}
				{renderName()}

				<button
					data-active={current === 5}
					className={'twoDValidateButton'}
					onClick={() => current === 5 && props.setView('3d')}
				>
					<div className={'twoDValidateText'}>
						{TEXTE.twoD.button}
					</div>
					<div className={'twoDValidateIcon'}>
						<div>
							<img src={current === 5 ? img_validateGreen : img_validate} alt={'V'} />
						</div>
					</div>
				</button>

				<div className={'twoDPieceContainer'}>
					{renderInital(1)}
					{renderInital(2)}

					<div className={'init row'}>
						{renderInital(3, img_empty3Little)}
						{renderInital(4, img_empty4Little)}
					</div>
				</div>
			{/* </div> */}

			{renderPieces()}

			{modal && renderModal()}
		</div>
	);
};