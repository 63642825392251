import React, {ReactElement} from "react";

import './TextContainer.css'

interface Props {
	title?: string | string[]
	text: string[]
	color?: 'yellow' | 'red',
	padding?: number | string
}


export default function TextContainer(props: Props) {
	let renderTitle: ReactElement[] = [];

	if (props.title) {
		const arrTitle = typeof props.title === 'string' ? [props.title] : props.title;

		renderTitle = arrTitle.map(t => <h3>{t}</h3>);
	}

	return (
		<div
			data-color={props.color}
			className={'textContainer'}
			style={props.padding ? {padding: props.padding} : {}}
		>
			{props.title && <div> {renderTitle}</div>}

			<p style={!props.title ? {margin: 0} : {}}>
				{props.text.map(t => <>{t}<br /></>)}
			</p>
		</div>
	);
}